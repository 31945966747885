import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cbs-configuration-wrap',
  template: `<router-outlet></router-outlet>`,
})
export class ConfigurationWrapComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
