import { catchError, delay, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { of as observableOf } from 'rxjs/internal/observable/of';

@Injectable()
export class PlansService {

  constructor(private httpClient: HttpClient) {
  }



  public getPlanList(loanId) {
    return this.httpClient.get(
      `${environment.API_ENDPOINT}loans/${loanId}/plansList`
    );
  }

  public getSelectedPlan(loanId,plan) {
    return this.httpClient.get(
      `${environment.API_ENDPOINT}loans/${loanId}/plans/${plan}`
    );
  }


}
