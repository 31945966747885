import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cbs-logo-svg-hor',
  templateUrl: 'cbs-logo-hor.component.html',
  styles: [':host { display: inline-block; height: auto; width: 60px;}']
})
export class CbsLogoSvgHorizontalComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
