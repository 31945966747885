import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoanAppAttachmentListService {

  constructor(private httpClient: HttpClient) {
  }

  getLoanAppAttachmentList(loanAppId): Observable<any> {
    return this.httpClient.get(
      `${environment.API_ENDPOINT}loan-applications/${loanAppId}/attachments`)
      .pipe(delay(environment.RESPONSE_DELAY));
  }

  pinLoanAppAttachment(loanAppId, attachmentId): Observable<any> {
    return this.httpClient.post(
      `${environment.API_ENDPOINT}loan-applications/${loanAppId}/attachments/${attachmentId}/pin`,
      null);
  }

  unpinLoanAppAttachment(loanAppId, attachmentId): Observable<any> {
    return this.httpClient.post(
      `${environment.API_ENDPOINT}loan-applications/${loanAppId}/attachments/${attachmentId}/unpin`,
      null);
  }
}
