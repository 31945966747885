import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute , Router} from '@angular/router';
import * as fromStore from '../../../core/store';
import { ILoanInfo } from '../../../core/store';
import { LoanInstallmentsTableComponent } from '../../../shared/components/cbs-loan-installments-table/loan-installments-table.component';
import { ILoanSchedule } from '../../../core/store/loans/loan-schedule/loan-schedule.reducer';
import * as fromRoot from '../../../core/core.reducer';
import { Subscription } from 'rxjs/Rx';
import { PlansService } from '../shared/services/plans.service';
import { BehaviorSubject } from 'rxjs';
const SVG_DATA = {collection: 'standard', class: 'task', name: 'task'};

@Component({
  selector: 'cbs-loan-schedule',
  templateUrl: 'loan-plans.component.html',
  styleUrls: ['loan-plans.component.scss']
})

export class PlansComponent implements OnInit, OnDestroy {
  @ViewChild(LoanInstallmentsTableComponent, {static: false}) installmentsTableComponent: LoanInstallmentsTableComponent;
  public breadcrumb = [];
  public installments: any;
  public plansList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedPlan: number = 1;
  public svgData = SVG_DATA;
  public loanId: number;
  public loanCode: string;

  private plansListSub: Subscription;
  private selectedPlanSub: Subscription;

  private breadcrumbLinks = [
    {
      name: 'LOANS',
      link: '/loans'
    }
  ];
  private loanScheduleSub: Subscription;
  private loanSub: Subscription;
  private routeSub: Subscription;
  public loanType: string;

  constructor(
    private plansService: PlansService,
    private loanStore$: Store<ILoanInfo>,
              private loanScheduleStore$: Store<ILoanSchedule>,
              private store$: Store<fromRoot.State>,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.routeSub = this.route.parent.parent.params.subscribe((params: { id: number, loanType: string }) => {
      if ( params && params.id ) {
        this.loanId = params.id;
        this.loanType = params.loanType;
      }
    });

    this.loanSub = this.loanStore$.pipe(select(fromRoot.getLoanInfoState))
      .subscribe(loan => {
        if ( loan['loaded'] && !loan['error'] && loan['success'] ) {
          const loanProfile = loan['loan'];
          this.loanCode = loanProfile['code'];
          const profileType = loanProfile['type'] === 'PERSON' ? 'people' : 'companies';
          this.breadcrumb = [
            {
              name: loanProfile['profile']['name'],
              link: `/profiles/${profileType}/${loanProfile['profile']['id']}/info`
            },
            {
              name: 'LOANS',
              link: '/loans'
            },
            {
              name: loanProfile['code'],
              link: ''
            },
            {
              name: 'OPERATIONS',
              link: `/loans/${loanProfile['id']}/operations`
            },
            {
              name: 'PLANS',
              link: ''
            }
          ];

        }
      });
    this.loanScheduleSub = this.store$.select(fromRoot.getLoanScheduleState)
      .subscribe((schedule: ILoanSchedule) => {
        if (schedule.loaded && schedule.success) {
          this.installments = schedule['loanSchedule'];
         
          if ( this.installmentsTableComponent ) {
            this.installmentsTableComponent.isLoading = false;
          }
        }
      });
    this.loanScheduleStore$.dispatch(new fromStore.LoadLoanSchedule(this.loanId));

    const plansList = [];
    this.plansListSub = this.plansService.getPlanList(this.loanId).subscribe((data: any[]) => {
      data.forEach((item) => { 
        plansList.push({id: item[4], name: 'plan-'+item[3]+' # '+item[2], code: item[1],main:item[3]})
      });
      this.plansList$.next(plansList);
    });
  
    this.selectedPlan =1;
  }

  onSelectedPlanChange(selectedOptions: any[]) {
    this.selectedPlanSub = this.plansService.getSelectedPlan(this.loanId,selectedOptions).subscribe(data => {
     
      this.installments =data;
    });

  }

  resetState() {
    this.loanScheduleStore$.dispatch(new fromStore.ResetLoanSchedule());
  }

  ngOnDestroy() {
    this.loanSub.unsubscribe();
    this.resetState();
    this.loanScheduleSub.unsubscribe();

    if (this.plansListSub) {
      this.plansListSub.unsubscribe();
    }
    if (this.selectedPlanSub) {
      this.selectedPlanSub.unsubscribe();
    }

  }
  cancel() {
    this.router.navigate(['loans', this.loanId, this.loanType, 'operations']);
  }
}
